import ProductStockReport from './product-stock-report'
import {AureliaReactReportWrapper} from "$pages/reports-react/AureliaReactReportWrapper";

class ProductStockReportReactWrapper extends AureliaReactReportWrapper {
  constructor() {
    super(ProductStockReport)
  }
}

export default ProductStockReportReactWrapper
