import React from 'react';
import { ColumnDefinition } from '$pages/reports-react/column-definition';
import { useCaseInsensitiveTranslation } from '$lib/hooks/case-insensitive-translation';
import {
  ProductStockReportDocument,
  ProductStockReportDownloadDocument,
  ProductStockReportDownloadQuery,
  ProductStockReportQuery, ProductStockReportQueryVariables,
  ReportFormatEnum,
  Reports,
  SortDirection
} from '$typings/graphql-codegen';
import { SortObject, useGenericReport } from '$pages/reports-react/generic-report';
import { displayMeasurement, IMeasurementUnit } from '$lib/unitConverters';
import { ReportGridDataHook } from '$pages/reports-react/generic-report-hooks';
import { ColumnWidth } from '$pages/reports-react/ColumnWidth';

// Shorten and simplify GraphQL types
type ReportResult = ProductStockReportQuery
type ReportVariables = ProductStockReportQueryVariables
type ReportData = ProductStockReportQuery["reports"]["productStock"]["data"][0] 
type ReportDownloadResult = ProductStockReportDownloadQuery
type Product = ProductStockReportQuery["reports"]["productStock"]["data"][0]["product"] 
type Unit = ProductStockReportQuery["reports"]["productStock"]["data"][0]["unit"] 

enum ColumnKey {
  Alias = 'alias',
  CustomerName = 'customerName',
  Address = 'address',
  ShipTo = 'shipTo',
  SoldTo = 'soldTo',
  CustomerRef = 'customerRef',
  ProductString = 'productString',
  SampleTime = 'sampleTime',
  ChannelAlias = 'channelAlias',
  FormattedValue = 'formattedValue',
  FormattedUnit = 'formattedUnit',
  Value = 'value',
}

interface PrettifiedReportData extends ReportData {
  [ColumnKey.ProductString]: string
  [ColumnKey.FormattedValue]: string
  [ColumnKey.FormattedUnit]: string
}

const ProductStockReport = () => {
  const [t] = useCaseInsensitiveTranslation()
  const Title: string = t('UI_Reports_ProductStock')
  const Filename: string = 'product-stock-report'
  
  // Used initially when table loads
  const DefaultSortSettings: SortObject = {
    sortColumnKey: ColumnKey.Alias,
    sortDirection: SortDirection.Asc
  }
  
  // Define columns
  const ColumnDefinitions: ColumnDefinition<PrettifiedReportData>[] = [
    new ColumnDefinition<PrettifiedReportData>(
      t('UI_Reports_ProductStockSummary_Table_Header_SiteAlias'),
      (row: PrettifiedReportData) => row[ColumnKey.Alias] ?? '',
      ColumnKey.Alias,
      ColumnWidth.Common.SiteName
    ),
    new ColumnDefinition<PrettifiedReportData> (
      t('UI_Reports_ProductStockSummary_Table_Header_Customer'),
      (row: PrettifiedReportData) => row[ColumnKey.CustomerName] ?? '',
      ColumnKey.CustomerName,
      ColumnWidth.Common.CustomerName
    ),
    new ColumnDefinition<PrettifiedReportData> (
      t('UI_Reports_ProductStockSummary_Table_Header_Address'),
      (row: PrettifiedReportData) => row[ColumnKey.Address] ?? '',
      ColumnKey.Address,
      ColumnWidth.Generic.Medium
    ),
    new ColumnDefinition<PrettifiedReportData> (
      t('UI_Reports_ProductStockSummary_Table_Header_ShipTo'),
      (row: PrettifiedReportData) => row[ColumnKey.ShipTo] ?? '',
      ColumnKey.ShipTo,
      ColumnWidth.Generic.Short
    ),
    new ColumnDefinition<PrettifiedReportData> (
      t('UI_Reports_ProductStockSummary_Table_Header_SoldTo'),
      (row: PrettifiedReportData) => row[ColumnKey.SoldTo] ?? '',
      ColumnKey.SoldTo,
      ColumnWidth.Generic.Short
    ),
    new ColumnDefinition<PrettifiedReportData> (
      t('UI_Reports_ProductStockSummary_Table_Header_CustomerRef'),
      (row: PrettifiedReportData) => row[ColumnKey.CustomerRef] ?? '',
      ColumnKey.CustomerRef,
      ColumnWidth.Common.CustomerName
    ),
    new ColumnDefinition<PrettifiedReportData> (
      t('UI_Reports_ProductStockSummary_Table_Header_Product'),
      (row: PrettifiedReportData) => row[ColumnKey.ProductString] ?? '',
      ColumnKey.ProductString,
      ColumnWidth.Common.ProductName
    ),
    new ColumnDefinition<PrettifiedReportData> (
      t('UI_Reports_ProductStockSummary_Table_Header_LastSampleTime'),
      (row: PrettifiedReportData) => row[ColumnKey.SampleTime] ?? '',
      ColumnKey.SampleTime,
      ColumnWidth.Generic.DateTime
    ),
    new ColumnDefinition<PrettifiedReportData> (
      t('ui_reports_temprature_table_header_channelalias'),
      (row: PrettifiedReportData) => row[ColumnKey.ChannelAlias] ?? '',
      ColumnKey.ChannelAlias,
      ColumnWidth.Common.ChannelName
    ),
    new ColumnDefinition<PrettifiedReportData> (
      t('UI_Reports_ProductStockSummary_Table_Header_Stock'),
      (row: PrettifiedReportData) => row[ColumnKey.FormattedValue] ?? '',
      ColumnKey.Value,
      ColumnWidth.Generic.Shorter
    ),
    new ColumnDefinition<PrettifiedReportData> (
      t('UI_Reports_ProductStockSummary_Table_Header_Unit'),
      (row: PrettifiedReportData) => row[ColumnKey.FormattedUnit] ?? '',
      ColumnKey.FormattedUnit,
      ColumnWidth.Generic.Shorter
    ),
  ]
  
  function stringifyProduct(product: Product | null): string {
    if (!product) return ''
    const slug: string = product.languageKey ?? ''
    const name: string = product.name ?? ''
    return slug ? t(slug) : name
  }
  
  function stringifyUnit(unit: Unit | null): string {
    if (!unit) return ''
    const slug: string = unit.translationKey
    const name: string = unit.symbol
    return slug ? t(slug) : name
  }
  
  function prettifyData(data: ReportData): PrettifiedReportData {
    const pretty: PrettifiedReportData = {...data} as PrettifiedReportData
    pretty[ColumnKey.ProductString] = stringifyProduct(pretty.product ?? null)
    pretty[ColumnKey.FormattedUnit] = stringifyUnit(pretty.unit ?? null)
    const num: number = pretty.value ?? 0
    const unit: IMeasurementUnit = pretty.unit as IMeasurementUnit
    pretty[ColumnKey.FormattedValue] = displayMeasurement(num, unit)
    return pretty
  }
  
  function dataSelector(result: ReportResult): PrettifiedReportData[] {
    const data: ReportData[] = (result?.reports?.productStock?.data ?? []) as ReportData[]
    return data.map(prettifyData)
  }
  
  function dataCounter(result: ReportResult): number {
    return dataSelector(result).length
  }
  
  function downloadUrlSelector(result: ReportDownloadResult): string {
    return result?.reports?.productStock?.blobUrl ?? ''
  }
  
  const { GenericReport } = useGenericReport<
    ReportResult,
    ReportVariables,
    ReportData,
    ReportDownloadResult
  >()
  
  function createTitle(data: ReportGridDataHook<ReportData>): JSX.Element {
    const n: number = data?.data?.length ?? 0
    const text: string = n ? `${Title} - ${n} tanks` : Title
    return <div>{text}</div>
  }

  return <GenericReport
    titleCallback={createTitle}
    reportEnum={Reports.ProductStock}
    supportedFormats={[ReportFormatEnum.Excel, ReportFormatEnum.Pdf, ReportFormatEnum.Csv]}
    mainQueryDocument={ProductStockReportDocument}
    downloadQueryDocument={ProductStockReportDownloadDocument}
    columnDefinitions={ColumnDefinitions}
    dataSelector={dataSelector}
    dataCounter={dataCounter}
    defaultSortSettings={DefaultSortSettings}
    downloadUrlSelector={downloadUrlSelector}
    downloadFilename={Filename}
    allowRunningUnfiltered={false} />
}

export default ProductStockReport
